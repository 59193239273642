// export const Others = 0
// export const MarketMyOffers = 1
// export const MarketOtherOffers = 2
// export const MarketOrdersPlaced = 3
// export const MarketOrdersReceived = 4
// export const FreightsSent = 5
// export const FreightsReceived = 6
// export const OthersHelpdesk = 7

export const Others = 0
export const VehicleOrders = 1
export const FreightOrders = 2
export const MyVehicleOrders = 3
export const MyFreightOrders = 4
export const OthersHelpdesk = 7
