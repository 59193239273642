<template>
  <div>
    <div class="d-flex align-center pl-3 py-3 pointer shadow-bottom" style="position:relative;z-index:1" @click="$emit('close')">
      <v-btn class="rounded" depressed icon x-small tile>
        <v-icon color="primary" size="22">$back</v-icon>
      </v-btn>
      <div class="pl-2 primary--text" style="font-size: 12px">{{ group.description }} ({{ group.count }})</div>
    </div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      class="progressLoading"
    ></v-progress-linear>
    <div v-if="offer">
      <Offer :offer="offer" @closeOffer="closeOffer" closable="true"></Offer>
      <Message
        v-for="message in conversations"
        :key="message.threadId"
        :message="message"
        @open="openMsg"
      ></Message>
    </div>
    <div v-else class="offersWrapp">
      <Offer
        v-for="offer in offers"
        :key="offer.id"
        :offer="offer"
        @open="openOffer"
      ></Offer>
      <div v-if="load" v-intersect="getOfferThreads" class="text-center mt-2">
        <v-progress-circular
          :width="3"
          :size="25"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>
<script>
import * as types from '@/store/modules/chat/chat-mutation-types'
import Offer from './Offer.vue'
import Message from './Message.vue'
import { markAsRead } from '@/signalr/chat.js'
export default {
  name: 'Offers',
  props: ['group'],
  components: { Message, Offer },
  data () {
    return {
      loading: false,
      offer: null
    }
  },
  computed: {
    offers () {
      return this.$store.getters['chat/offers']
    },
    load () {
      return this.$store.getters['chat/loadOffers']
    },
    conversations () {
      return this.$store.getters['chat/threads']
    },
    loadThreads () {
      return this.$store.getters['chat/loadThreads']
    }
  },
  async created () {
    this.$store.commit(`chat/${types.CLEAR_OFFERS}`)
    await this.getOfferThreads()
  },
  methods: {
    async getOfferThreads () {
      this.loading = true

      await this.$store.dispatch('chat/getOffers', this.group.threadType)

      this.loading = false
    },
    async openOffer (offer) {
      offer.readByCurrentUser = true
      if (offer.conversationId) {
        this.$store.dispatch('chat/openConversation', { conversationId: offer.conversationId, threadId: offer.id })
      } else {
        this.$store.commit(`chat/${types.CLEAR_THREADS}`)

        this.offer = offer
        this.loading = true

        await this.$store.dispatch('chat/getOfferThreads', offer.orderId)

        this.loading = false
      }
    },
    closeOffer () {
      this.offer = null
    },
    openMsg (message) {
      const found = this.conversations.find((msg) => msg.conversationId === message.conversationId && msg.threadId === message.threadId)
      if (!found) return
      if (!found.readByCurrentUser) {
        found.readByCurrentUser = true
        markAsRead(found.threadId, found.messageId)
      }
      this.$store.dispatch('chat/openConversation', { conversationId: found.conversationId, threadId: found.threadId })
    }
  }
}
</script>
